<template>
<div>
    <div ref="header" class="z-3 w-100 fixed white py-0 my-0">
        <v-row>
            <v-col cols="5" xl="3" lg="4" md="6" sm="6" class="py-0 my-0">
                <div class="mx-6 mt-6">
                    <Rogo13 />
                    <p class=" ml-2 f-12 f-gray float-left">管理画面</p>
                    <div class="clear"></div>
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="5" xl="9" lg="8" md="5" sm="5" class="py-0 my-0">
                <div class="float-right mt-3 white">
                    <v-btn class="blue mt-3 mr-2 dis-xxs float-right" dark @click="menuOpen()">MENU</v-btn>
                    <div class="clear"></div>
                    <div ref="menu" class="disno-xxs">
                    <router-link :to="menu.path" v-for="menu in menus" :key="menu.path" :class="{'noevent':$route.path==menu.path}">
                        <div class="float-left mx-xl-10 mx-lg-4 pt-5 font_color f-12" v-text="menu.text"></div>
                    </router-link>
                    <div class="float-left mx-xl-10 mx-lg-4 pt-5 font_color f-12 f-gray pointer" @click="logout">
                        <v-icon>fas fa-sign-out-alt</v-icon><span class="f-08 pl-1">ログアウト</span>
                    </div>
                    <div class="clear"></div>
                    </div>
                </div>
                <div class="clear"></div>
            </v-col>
        </v-row>
    </div>
</div>
</template>

<script>
import Rogo13 from "@/components/Rogo13.vue"
import axios from "axios"
export default {
    data() {
        return {
            menus: [{
                    "path": "/master/blog",
                    "text": "ブログ"
                },
                {
                    "path": "/master/facility",
                    "text": "施設案内写真"
                },
                {
                    "path": "/master/tearm",
                    "text": "施設案内リスト"
                },
                {
                    "path": "/master/fees",
                    "text": "料金設定"
                },
                {
                    "path": "/master/faq",
                    "text": "よくある質問"
                },
                {
                    "path": "/master/contact",
                    "text": "予約状況"
                },
            ],
        }
    },
    components: {
        Rogo13
    },
    mounted() {},
    methods: {
        async logout() {
            await axios.post('/master/logout')
            window.location.href = '/';
        },
        menuOpen(){
            if(this.$refs.menu.classList=="disno-xxs"){
            this.$refs.menu.classList.remove("disno-xxs")
            }else{
                this.$refs.menu.classList.add("disno-xxs")
            }
        }
    },

    watch: {}
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/header.scss";
</style>
