<template>
<div v-if="loginShow&&show">
    <MasterHeader />
    <v-container class="contact_box" fluid>
        <h1 class="text-center mb-10 page_title">予約状況 設定</h1>
        <p class="text-center">日にちをクリックすると、予約状況を設定できます</p>
        <v-card class="px-6 py-4 calendar mx-auto">
            <div class="text-right">
                <v-btn x-large color="blue" dark class="mr-5 mb-3" @click="save()">保存</v-btn>
            </div>

            <div>
                <div class="f-12 mb-3">シーズンオフ設定</div>
                <v-row>
                    <v-col cols="6">
                        <v-menu ref="startPic" v-model="startPic" :close-on-content-click="false" :return-value.sync="offStart" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="offStart" label="開始" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="offStart" no-title scrollable>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6">
                        <v-menu ref="endPic" v-model="endPic" :close-on-content-click="false" :return-value.sync="offEnd" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="offEnd" label="終了" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="offEnd" no-title scrollable>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </div>
         <CalendarColor/>
            <div>
                <div class="float-left">
                    <v-btn icon class="ma-2" @click="$refs.calendar.prev();setEvent()" :class="{opacity0:toMonth==month||first==1&&firstMonth==month,noevent:toMonth==month||first==1&&firstMonth==month}">
                        <v-icon>fas fa-chevron-left</v-icon>
                    </v-btn>
                    {{month}}
                    <v-btn icon class="ma-2" @click="$refs.calendar.next();setEvent()" :class="{opacity0: nextMonth==month,noevent:nextMonth==month}">
                        <v-icon>fas fa-chevron-right</v-icon>
                    </v-btn>
                </div>
                <div class="clear"></div>
            </div>
            <v-calendar :load="setEvent()" ref="calendar" :events="events" :event-text-color="getColor" v-model="value" @click:date="viewDay" locale="ja-jp" :day-format="(timestamp) => new Date(timestamp.date).getDate()" :month-format="(timestamp) => (new Date(timestamp.date).getMonth() + 1) + ' /'">
            </v-calendar>
        </v-card>
    </v-container>
</div>
</template>

<script>
import zip from '@/mixins/zip'
import calendar from '@/mixins/calendar'
import moment from 'moment';
import axios from "axios"
import d from '@/d'
import Login from '@/mixins/login'
import MasterHeader from '@/components/MasterHeader.vue'

import {
    validationMixin
} from 'vuelidate'
import {
    required,
    email,
} from 'vuelidate/lib/validators'

import CalendarColor from '@/components/CalendarColor.vue';

export default {
    mixins: [validationMixin, zip, calendar, Login],

    name: "Contact",

    data() {
        return {
            startPic: false,
            endPic: false,
        }
    },

    validations: {
        email: {
            required,
            email
        },
    },
    components: {
        MasterHeader,
        CalendarColor
    },

    computed: {
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push('メールアドレスの形式が違います');
            !this.$v.email.required && errors.push('入力必須です');
            return errors
        },
    },
    methods: {
        viewDay(val) {
            const first = new Date(val.date);
            const second = first;
            if (this.nearFulldays.indexOf(val.date) == -1 && this.fulldays.indexOf(val.date) == -1) {

                this.nearFulldays.push(val.date)
                this.events.push({
                    name: "残りわずか",
                    start: first,
                    end: second,
                    color: "rgba(0,0,0,0)",
                    textColor: "rgba(0,0,0,0)"
                });
            } else if (this.nearFulldays.indexOf(val.date) != -1 && this.fulldays.indexOf(val.date) == -1) {
                this.events.splice(this.events.findIndex(({
                    start
                }) => moment(start).format('YYYY-MM-DD') == moment(first).format('YYYY-MM-DD')), 1)

                this.fulldays.push(val.date)
                this.nearFulldays.splice(this.nearFulldays.indexOf(val.date), 1)

                this.events.push({
                    name: "空きなし",
                    start: first,
                    end: second,
                    color: "rgba(0,0,0,0)",
                    textColor: "rgba(0,0,0,0)"
                });
            } else if (this.nearFulldays.indexOf(val.date) == -1 && this.fulldays.indexOf(val.date) != -1) {

                this.fulldays.splice(this.fulldays.indexOf(val.date), 1)

                this.events.splice(this.events.findIndex(({
                    start
                }) => moment(start).format('YYYY-MM-DD') == moment(first).format('YYYY-MM-DD')), 1)
            }
        },
        async setEvent() {
            await d.sleep(10)
            this.setColor()
            // if (this.toMonth == this.month) {
            //     for (let i = 0; i < document.getElementsByClassName('v-calendar-weekly__day').length; i++) {
            //         if (document.getElementsByClassName('v-calendar-weekly__day')[i].innerText.indexOf(moment().format("M")+" / 1") != -1) {
            //             document.getElementsByClassName('v-calendar-weekly__day')[i].classList.add('noevent')
            //             break;
            //         }
            //     }
            // }
        },

        async save() {
            const data = {
                nearFull: JSON.stringify(this.nearFulldays),
                full: JSON.stringify(this.fulldays),
                offStart:this.offStart,
                offEnd:this.offEnd
            }

            await axios.post('/master/contact/save', data)
            window.location.href = "/master/contact";
        },
    },
    watch: {
        offStart(val) {
            try {
                this.$refs.startPic.save(val)
            } catch (e) {
                console.log()
            }
        },
        offEnd(val) {
            try {
                this.$refs.endPic.save(val)
            } catch (e) {
                console.log()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/master/contact.scss";
</style>
