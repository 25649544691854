import axios from "axios";

export default {
    data() {
        return {
            login: 0,
            loginShow:false,
        };
    },
    async created() {
        const response = await axios.post("/master/check");
        this.login = response.data.login;


        if (!this.login) {
            window.location.href = '/'; 
        }else{
            this.loginShow=true
        }
    }
};
